/*
 * @Author: 冯杰
 * @Date: 2021-10-12 20:33:14
 * @LastEditTime: 2021-10-13 15:19:06
 * @FileName: 经销商红包提现明细
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'DealerRedpacketDithdraw',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/cashRecord/cashRecordReport/findDealerByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
  },
  methods: {},
  created() {
    // 重写获取配置信息
    this.getConfigList('dealer_redpacket_withdraw');
  },
};
